import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { validateEmail as validateEmailApi } from '../../api';
import { reportToSentry } from '../../helpers/sentry';
import { setContactEmailIsValid } from '../../actions/application';
import { clearApplicationError } from '../../actions/quote';

export type EmailValidationResponse = {
  isValid: boolean;
  isWarning: boolean;
  confidenceValue: number;
  issueType: string;
  suggestedAddress?: string;
};

export const getEmailValidationMessage = (error: EmailValidationResponse, email: string) => {
  switch (error.issueType) {
    case 'PossibleTypo':
      return `Did you mean ${error.suggestedAddress}?`;
    case 'TemporaryAddress':
      return 'This looks like a temporary email. We need a valid email to send information to the policyholder.';
    case 'ConfidenceTooLow':
      return "We can't verify this email. We require a valid policyholder email to access secure policy information and Billing Center.";
    default:
      return `"${email}" is not a valid email. Please correct and double check for spaces at the beginning or end.`;
  }
};

const warningTypes = ['PossibleTypo', 'ConfidenceTooLow'];

export const checkForWarnings = (error: AxiosResponse<EmailValidationResponse>) => {
  const data = error.data;
  return { ...data, isWarning: warningTypes.includes(data?.issueType) };
};

export const useValidateEmail = () => {
  const dispatch = useDispatch();
  const [emailValidationError, setEmailValidationError] = useState('');
  const [emailValidationWarning, setEmailValidationWarning] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [hasEmailWarning, setHasEmailWarning] = useState(false);

  const validateEmail = async (email: string | undefined) => {
    setEmailValidationWarning('');
    setEmailValidationError('');
    setHasEmailWarning(false);
    dispatch(clearApplicationError());

    if (!email) return;

    try {
      const response = await validateEmailApi(email);
      const data = checkForWarnings(response);

      if (data.isWarning) {
        dispatch(setContactEmailIsValid(true));
        setEmailValidationWarning(getEmailValidationMessage(data, email));
        setHasEmailWarning(true);
        return;
      }

      dispatch(setContactEmailIsValid(data.isValid));
      setEmailValidationError(getEmailValidationMessage(data, email));
      setIsEmailValid(data.isValid);
    } catch (error) {
      reportToSentry(error as any);
    }
  };

  return {
    validateEmail,
    emailValidationError,
    emailValidationWarning,
    isEmailValid,
    hasEmailWarning,
  };
};

export const validateEmailRegex = (email: string | undefined) => {
  if (!email) return false;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
};
