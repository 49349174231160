import React from 'react';
import styled from 'styled-components';
import CallToActionBar from '../Shared/CallToActionBar';

const FooterContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  height: 160px;
  box-sizing: border-box;
  width: 100vw;
  background-color: ${({ theme }) => theme.pageComponents.globalComponents.footerContainer.background};
  z-index: 6;
  display: flex;
  @media (max-width: 767px) {
    padding-bottom: 24px;
  }
  @media print {
    display: none;
  }
`;

/**
 * Necessary to ensure the fixed footer doesn't cover content.
 */
const FalseFooter = styled.div`
  height: 160px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding-bottom: 24px;
  }
`;

const Footer = () => {
  return (
    <>
      <FalseFooter />
      <FooterContainer>
        <CallToActionBar />
      </FooterContainer>
    </>
  );
};

export default Footer;
