import React from 'react';
import ReactSwitch from 'react-switch';
import { useThemeContext } from './theme/CustomThemeProvider';

type OnChange = (
  checked: boolean,
  event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
  id: string
) => void;

type Options = {
  backgroundColor: string;
};

interface SwitchProps {
  onChange: OnChange;
  checked: boolean;
  dataCy?: string;
  ariaLabel: string;
  options?: Options;
  dataTestId?: string;
  name?: string;
}

const Switch = ({ onChange, checked, dataCy, ariaLabel, options, dataTestId, name }: SwitchProps) => {
  const { theme } = useThemeContext();
  const colorOpts = options || { backgroundColor: theme.formElements.switch.background };
  return (
    <ReactSwitch
      name={name}
      onChange={onChange}
      checked={checked}
      uncheckedIcon={false}
      checkedIcon={true}
      onColor={colorOpts.backgroundColor || theme.formElements.switch.background}
      offColor={colorOpts.backgroundColor || theme.formElements.switch.background}
      offHandleColor={theme.formElements.switch.unselected}
      onHandleColor={theme.formElements.switch.selected}
      activeBoxShadow={theme.formElements.switch.boxShadowOnActive}
      data-cy={dataCy}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      id={ariaLabel}
      height={24}
      width={48}
    />
  );
};

export default Switch;
