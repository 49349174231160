import { PAGES, POLICY_ENUMS } from '../constants';
import isApplicationBindable from '../helpers/isApplicationBindable';
import renderPremium from '../helpers/renderPremium';
import { selectApplication } from './application';
import { getCurrentPage, getPaymentInterval } from './routing';

export const selectQuote = (state) => state.quote || {};
export const selectQuotes = (state) => selectQuote(state).quotes || [];
export const selectQuoteId = (state) => selectQuote(state).externalId;
export const selectPremium = (state) => selectQuote(state).premium;
export const selectMonthlyPremium = (state) => selectQuote(state).monthlyPremium;
export const selectStateNoticeText = (state) => selectQuote(state).stateNoticeText;
export const selectInsuranceCarrier = (state) => selectQuote(state).insuranceCarrier;
export const selectExclusions = (state) => selectQuote(state).exclusions ?? [];

export const selectBOPRatingEngine = (state) =>
  selectQuotes(state).reduce((accum, quote) => {
    if (quote.policyType !== POLICY_ENUMS.BOP) return accum;
    return quote.ratingEngine || null;
  }, null);

export const getQuoteAmount = (state) => {
  const isPaymentPage = getCurrentPage(state) === PAGES.PAYMENT;
  const isBindable = isApplicationBindable(selectApplication(state));
  const paymentInterval = getPaymentInterval(state);
  const premium = selectPremium(state);
  const monthlyPremium = selectMonthlyPremium(state);

  return (isBindable || isPaymentPage) && paymentInterval === 'None'
    ? renderPremium(premium)
    : renderPremium(monthlyPremium?.toFixed(2));
};

export const getQuoteAmountByType = (state) => {
  return selectQuotes(state).reduce((accum, quote) => {
    const monthlyPremium = parseFloat(quote.premium) / 12;
    accum[quote.policyType] = {
      annual: renderPremium(quote.premium.toFixed(2)),
      monthly: renderPremium(monthlyPremium.toFixed(2)),
    };

    return accum;
  }, {});
};

export const selectFees = (state) => selectQuote(state)?.fees ?? [];

// policy fees and installment fees
export const selectMonth1Fees = (state) => selectQuote(state)?.month1Fees;

// monthly premium + month1Fees
export const selectMonth1Owed = (state) => selectQuote(state)?.month1Owed;

// policy fees + installment fees, when user pays yearly
export const selectTotalYearlyFees = (state) => selectQuote(state)?.totalYearlyFees;

// yearly premium + totalYearlyFees, when user pays yearly
export const selectTotalYearlyOwed = (state) => selectQuote(state)?.totalYearlyOwed;

// future monthly fees per month
export const selectMonthlyFees = (state) => selectQuote(state)?.monthlyFees;

// future monthly premium + monthlyFees
export const selectMonthlyOwed = (state) => selectQuote(state)?.monthlyOwed;

export const selectPolicyFees = (state) => selectQuote(state)?.policyFees;
export const selectInstallmentFees = (state) => selectQuote(state)?.installmentFees;

export const selectHasFees = (state) => Boolean(selectTotalYearlyFees(state));
export const selectHasPolicyFee = (state) => Boolean(selectPolicyFees(state));
export const selectFeesSavings = (state) => selectInstallmentFees(state) * 11;

export const selectHasFirstMonthWaived = (state) =>
  selectFees(state).find((fee) => fee?.description === 'Installment Fee')?.offset === 1;

export const selectLossControlFees = (state) => selectQuote(state)?.lossControlFees;

export const selectCyberFee = (state) =>
  selectFees(state).find((fee) => fee?.feeType === 'CyberClaimandRiskManagementFee');
