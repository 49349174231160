import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { updateApplicationOnServer } from '../api';
import { applicationError } from './quote';
import { PAGES } from '../constants';
import handleRateCallTwo from './handleRateCallTwo';
import {
  setAvailablePolicyTypes,
  updateApplicationFromServer,
  fatalError,
  setApplicationIsLoading,
  updateApplicationLocally,
} from './application';
import { addSource } from '../helpers/addSource';
import { getIsDropin } from '../selectors/routing';
import { selectPartnerAgencyExternalId, selectAgentProducerExternalId } from '../selectors';
import minimumApplicationFieldsRequired from '../helpers/minimumApplicationFieldsRequired';
import { applicationStartDateValidator } from '../helpers/applicationStartDateValidator';
import { PolicyType } from '../types/enums';
import { pipe } from '../helpers/functionalHelpers';
import { getHasInvalidPolicyError } from '../helpers/getHasInvalidPolicyError';
import { handleGetAvailablePolicyTypes } from './handleGetAvailablePolicyTypes';
import addApplicationAggregate from '../helpers/addApplicationAggregate';
import { clearApplicationError } from './quote';
import { selectIsAgentMDA } from '../selectors/agent';

const handleUpdateApplication = (data?: any) => {
  return (dispatch: any, getState: any) => {
    const {
      application: existingApplication,
      availablePolicyTypes,
      location: { type },
    } = getState();

    const appToSendToServer = Object.assign({}, existingApplication, data);
    const validatedApplication = pipe(applicationStartDateValidator, addApplicationAggregate)(appToSendToServer);

    if (!minimumApplicationFieldsRequired(validatedApplication)) {
      dispatch(updateApplicationLocally(validatedApplication));
      return;
    }

    dispatch(setApplicationIsLoading(true));
    dispatch(clearApplicationError());
    const sourceData = addSource({ isDropin: getIsDropin(getState()), isMDA: selectIsAgentMDA(getState()) });

    const producerExternalId = selectAgentProducerExternalId(getState());
    const agencyExternalId = selectPartnerAgencyExternalId(getState());
    const addProducerOrAgencyExternalId = producerExternalId ? { producerExternalId } : { agencyExternalId };

    const requestData = {
      ...validatedApplication,
      ...sourceData,
      ...addProducerOrAgencyExternalId,
    };
    return updateApplicationOnServer(requestData)
      .then((res: any) => {
        if (res.data.errors) {
          dispatch(setApplicationIsLoading(false));
          const hasInvalidPolicyError = getHasInvalidPolicyError(res.data.errors);
          const needsAvailablePolicyTypes = availablePolicyTypes?.length === 3;

          /**
           * On a prefilled application without an industry ID, the backend will send all policies as availableTypes
           * Neither the updateApplication call nor fail fast receives the available types when backend sends an error obj
           * In order to display a helpful error message, we need to ask the server for available types by sending a post
           * request without a policy type set so it doesn't error out.
           *
           * TODO update the backend to send available policy types when an invalid policy error is detected so the front end
           * doesn't have to make an extra call
           */
          if (hasInvalidPolicyError && needsAvailablePolicyTypes) {
            dispatch(handleGetAvailablePolicyTypes(res.data.errors));
          } else {
            dispatch(applicationError(res.data.errors));
          }
          return;
        } else if (res.data.availablePolicyTypes?.length === 0) {
          dispatch(setApplicationIsLoading(false));
          dispatch(applicationError(['No available policy types']));
          return;
        }

        const applicationTypesValid =
          res.data.application?.applicationTypes &&
          res.data.application.applicationTypes.reduce((accum: boolean, type: PolicyType) => {
            if (!accum) return accum;
            if (!res.data.availablePolicyTypes.includes(type)) {
              return false;
            }
            return accum;
          }, true);
        const applicationFromServer = applicationTypesValid
          ? { ...res.data.application }
          : { ...res.data.application, applicationTypes: [] };

        dispatch(setAvailablePolicyTypes(res.data.availablePolicyTypes));
        dispatch(
          updateApplicationFromServer({
            ...applicationFromServer,
          })
        );

        if ((type === PAGES.SUMMARY || type === PAGES.PAYMENT) && res.data.application?.applicationId) {
          dispatch(handleRateCallTwo());
        }

        dispatch(setApplicationIsLoading(false));
      })
      .catch((error) => {
        reportToSentry(error, getRequestContext({ requestData }));
        dispatch(fatalError(error));
        dispatch(setApplicationIsLoading(false));
      });
  };
};

export default handleUpdateApplication;
