import { ERRORS } from '../constants';

export const quoteHelpCopy = (error) => {
  switch (error) {
    case ERRORS.CAT_1.NUMBER_OF_EMPLOYEES_OUTSIDE_BOUNDS:
    case ERRORS.CAT_1.PREVIOUS_LOSSES_OUTSIDE_BOUNDS:
    case ERRORS.CAT_1.REVENUE_OUTSIDE_BOUNDS:
      return 'Based on the specialized nature and/or size of your business operations one of our agents will need to assist with their policy quote.';
    case ERRORS.CAT_1.ZIP_CODE_OUTSIDE_BOUNDS:
      return "We're sorry, It looks like we are not currently offering insurance in your area. If you have any questions please call the number below.";
    case ERRORS.CAT_1.INVALID_INDUSTRY_ID:
      return "It looks like we don't currently support your industry.";
    default:
      return 'Unfortunately we are unable to complete your application at this time.';
  }
};
