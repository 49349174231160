import { SET_AGENT, SET_IS_MDA, SET_AGENT_PRODUCER_EXTERNAL_ID } from './../actions/agent';

const initialState = { isMDA: false };

export function agent(state: Agent = initialState, action: Action) {
  switch (action.type) {
    case SET_AGENT:
      return Object.assign({}, state, action.data);
    case SET_IS_MDA:
      return Object.assign({}, state, { isMDA: true });
    case SET_AGENT_PRODUCER_EXTERNAL_ID:
      return Object.assign({}, state, { producerExternalID: action.data });
    default:
      return state;
  }
}
