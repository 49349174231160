import React from 'react';
import styled from 'styled-components';
import handleUpdateApplication from '../actions/handleUpdateApplication';
import { POLICY_NAMES } from '../constants';
import { PolicyType } from '../types/enums';
import BOPtoGL from '../icons/ErrorModal/InvalidPolicy/BOPtoGL.svg';
import BOPtoPL from '../icons/ErrorModal/InvalidPolicy/BOPtoPL.svg';
import GLtoBOP from '../icons/ErrorModal/InvalidPolicy/GLtoBOP.svg';
import GLtoPL from '../icons/ErrorModal/InvalidPolicy/GLtoPL.svg';
import PLtoBOP from '../icons/ErrorModal/InvalidPolicy/PLtoBOP.svg';
import PLtoGL from '../icons/ErrorModal/InvalidPolicy/PLtoGL.svg';
import { CAT_1_REASONABILITY_ERRORS } from '../middleware/errorInterceptor';
import getPolicyAssets from './getPolicyAssets';

const Error = styled.div`
  margin: 8px 0;
`;

const ErrorMessageContainer = styled.div`
  display: flex;
`;

const ErrorMessage = styled.p`
  margin: 0 0 0 10px;
`;

export const contactUsCopy =
  'Please update your policy selection. If you have any questions please reach out to our Customer Service team through the Chat in the bottom right of your screen!';

export const fallbackMessageCopy =
  "There was a problem with your application. Please verify the information you've submitted and try again.";

export const invalidPolicyErrorHeaderCopy = 'Looks like we need to change something.';

export const invalidPolicyErrorCTACopy = 'Update Policy Selection';

export const defaultHeaderCopy = 'Can you fix something?';

export const defaultCtaCopy = 'Go Back & Fix';

const getInvalidPolicyIcon = (validPolicy: string, invalidPolicy: string) => {
  let src;
  let alt;

  /* NOTE: the order these args are passed in is revered in this check
   *  The copy text has valid policy then invalid policy, while the icons are ordered the
   *  other way around.
   */
  switch (invalidPolicy + validPolicy) {
    case POLICY_NAMES.GL + POLICY_NAMES.BOP:
      src = GLtoBOP;
      alt = 'GL to BOP policy';
      break;
    case POLICY_NAMES.GL + POLICY_NAMES.PL:
      src = GLtoPL;
      alt = 'GL to PL policy';
      break;
    case POLICY_NAMES.PL + POLICY_NAMES.BOP:
      src = PLtoBOP;
      alt = 'PL to BOP policy';
      break;
    case POLICY_NAMES.PL + POLICY_NAMES.GL:
      src = PLtoGL;
      alt = 'PL to GL policy';
      break;
    case POLICY_NAMES.BOP + POLICY_NAMES.PL:
      src = BOPtoPL;
      alt = 'BOP to PL policy';
      break;
    case POLICY_NAMES.BOP + POLICY_NAMES.GL:
    default:
      src = BOPtoGL;
      alt = 'BOP to GL policy';
      break;
  }

  return <img src={src} alt={alt} />;
};

export const buildErrorMessageText = (validPolicy: string, invalidPolicy: string) =>
  validPolicy !== invalidPolicy
    ? `It looks like your business may need a ${validPolicy} instead of a ${invalidPolicy}.`
    : `Unfortunately, it looks like your business does not qualify for a ${invalidPolicy} Policy. Thankfully, we can offer you different policy that meets your needs.`;

export const buildErrorMessage = (validPolicy: string, invalidPolicy: string) => (
  <>
    {getInvalidPolicyIcon(validPolicy, invalidPolicy)}
    <ErrorMessage>{buildErrorMessageText(validPolicy, invalidPolicy)}</ErrorMessage>
  </>
);

const invalidPolicyCallback = () => ({
  action: handleUpdateApplication,
  payload: { applicationTypes: null },
  redirectTo: 'POLICIES',
});

const invalidPolicyMessageBuilder = (invalidPolicy: string) => (validPolicy: string) => (
  <>
    <ErrorMessageContainer>{buildErrorMessage(validPolicy, invalidPolicy)}</ErrorMessageContainer>
    <p>{contactUsCopy}</p>
  </>
);

export const defaultErrorTheme = {
  headerCopy: defaultHeaderCopy,
  ctaCopy: defaultCtaCopy,
  showSubheader: true,
  useAltStyle: false,
};

export const invalidPolicyTheme = {
  headerCopy: invalidPolicyErrorHeaderCopy,
  ctaCopy: invalidPolicyErrorCTACopy,
  showSubheader: false,
  useAltStyle: true,
};

export interface ErrorTheme {
  headerCopy: string;
  ctaCopy: string;
  showSubheader: boolean;
  useAltStyle: boolean;
}

interface ErrorObj {
  MESSAGE: Function;
  CALLBACK?: Function;
  THEME?: ErrorTheme;
}

interface ErrorMessageMap {
  E0031: ErrorObj;
  E0032: ErrorObj;
  E0033: ErrorObj;
  FALLBACK: ErrorObj;
  [key: string]: ErrorObj;
}

interface Options {
  validPolicy: PolicyType;
}

export const ERROR_MESSAGE_MAP: ErrorMessageMap = {
  E0031: {
    MESSAGE: invalidPolicyMessageBuilder(POLICY_NAMES.GL),
    CALLBACK: invalidPolicyCallback,
    THEME: invalidPolicyTheme,
  },
  E0032: {
    MESSAGE: invalidPolicyMessageBuilder(POLICY_NAMES.BOP),
    CALLBACK: invalidPolicyCallback,
    THEME: invalidPolicyTheme,
  },
  E0033: {
    MESSAGE: invalidPolicyMessageBuilder(POLICY_NAMES.PL),
    CALLBACK: invalidPolicyCallback,
    THEME: invalidPolicyTheme,
  },
  FALLBACK: {
    MESSAGE: () => <p>{fallbackMessageCopy}</p>,
  },
};

export const messageRemapperUtility = (errorArray: Array<ErrorMessage | string>, options: Options) => {
  const errorCallbacks: Array<Function> = [];
  let errorModalTheme = defaultErrorTheme;

  const errorMessages = errorArray
    .filter((errorMessage: ErrorMessage | string) => {
      const error = typeof errorMessage !== 'string' ? errorMessage.message : errorMessage;
      return !CAT_1_REASONABILITY_ERRORS.reduce((accum: boolean, subString: string) => {
        if (accum) return true;
        return error?.includes(subString);
      }, false);
    })
    .map((errorMessage: ErrorMessage | string, i: number) => {
      const error = typeof errorMessage !== 'string' ? errorMessage.message : errorMessage;
      if (typeof error !== 'string') {
        return <Error key={i}>{ERROR_MESSAGE_MAP.FALLBACK.MESSAGE()}</Error>;
      }

      let message = error;
      const errorId = error.slice(0, 5);
      const errorRemapData = ERROR_MESSAGE_MAP[errorId];

      if (errorRemapData) {
        const validPolicyName = getPolicyAssets(options.validPolicy)?.title;
        const validPolicy = validPolicyName ? `${validPolicyName} Policy` : 'different policy';
        message = errorRemapData.MESSAGE(validPolicy);
        if (errorRemapData.THEME) errorModalTheme = errorRemapData.THEME;

        /**
         * If the error requires an action to be fired, it will pass that action and paylaod back so it can be dispatched.
         * An exmaple of this is to reset policyTypes to null if they are invalid, allowing the app to update with valid Policy Types
         * for the user's industry and to deselect the invalid policy.
         * */
        const callback = errorRemapData.CALLBACK;
        if (callback && !errorCallbacks.includes(callback)) {
          errorCallbacks.push(callback);
        }
      }

      return <Error key={i}>{message}</Error>;
    });

  return { errorMessages, errorCallbacks, errorModalTheme };
};
