import React from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { SectionHeader, SectionHeaderCont } from '../styles';
import { SectionCont, HeaderSubCont, PolicyTitle, PolicyPrice } from '../Policies/styles';
import { Switch } from '../../../elements';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';
import ShieldIcon from '../../../icons/shield_yellow_loss_control.svg';
import CheckmarkIcon from '../../../icons/Checkmark.svg';
import { selectLossControl } from '../../../selectors/application';
import { selectLossControlFees } from '../../../selectors/quote';
import { getPaymentInterval } from '../../../selectors/routing';
import { setLossControl } from '../../../actions/application';
import { formatCurrency } from '../../../helpers/formatNumber';

interface PropTypes {
  setLossControl: (lossControl: string) => void;
}

const AddedServices = ({ setLossControl }: PropTypes) => {
  const { theme } = useThemeContext();

  const paymentInterval = useSelector(getPaymentInterval);
  const isYearly = paymentInterval === 'None';
  const lossControl = useSelector(selectLossControl);
  const lossControlFees = useSelector(selectLossControlFees);
  const hasLossControl = Boolean(lossControl === 'OptIn');

  const handleOnChange = () => {
    setLossControl(hasLossControl ? 'OptOut' : 'OptIn');
  };

  const savingsMessage = hasLossControl
    ? 'You are saving up to 10% on your premium'
    : 'Save up to 10% in premium when you enroll';

  const priceMessage = `Added for ${
    isYearly ? formatCurrency(lossControlFees * 12) : `${formatCurrency(lossControlFees)}/mo`
  }`;

  return (
    <Cont>
      <SectionHeader>Added Services</SectionHeader>
      <SectionCont isSelected={hasLossControl}>
        <SavingsCont hasLossControl={hasLossControl}>
          {hasLossControl && <CheckmarkSmall src={CheckmarkIcon} alt="checkmark" />}
          <SavingsMessage>{savingsMessage}</SavingsMessage>
        </SavingsCont>
        <SectionHeaderCont>
          <HeaderSubCont>
            <Icon src={ShieldIcon} alt="" />
            <div>
              <PolicyTitle>Manage My Risk Program</PolicyTitle>
              <p>
                Boost your client's protection with our self-guided and self-directed Manage My Risk Program.{' '}
                <ReadMoreLink
                  href="https://explore.coterieinsurance.com/manage-my-risk-policyholders"
                  target="_blank"
                  rel="noopener"
                >
                  Read more
                </ReadMoreLink>
              </p>
              {hasLossControl && Boolean(lossControlFees) && (
                <PriceCont>
                  <Checkmark src={CheckmarkIcon} alt="checkmark" />
                  <PolicyPrice>{priceMessage}</PolicyPrice>
                </PriceCont>
              )}
            </div>
          </HeaderSubCont>
          <Switch
            onChange={handleOnChange}
            checked={hasLossControl}
            ariaLabel={`${hasLossControl ? 'deselect' : 'select'} Manage My Risk program`}
            options={{ backgroundColor: theme.formElements.switch.backgroundAlt }}
          />
        </SectionHeaderCont>
      </SectionCont>
    </Cont>
  );
};

const mapDispatchToProps = {
  setLossControl,
};

const Cont = styled.div`
  margin-bottom: 16px;
`;

const SavingsCont = styled.div<{ hasLossControl: boolean }>`
  display: flex;
  column-gap: 4px;
  margin-bottom: 16px;
  background-color: ${(props) =>
    props.hasLossControl ? props.theme.colors.secondary.twentyFive : props.theme.colors.primary.ten};
  width: fit-content;
  padding: 4px 12px;
  border-radius: 4px;
`;

const SavingsMessage = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.09px;
`;

const Icon = styled.img`
  height: 50px;
  margin-right: 24px;
`;

const PriceCont = styled.div`
  display: flex;
`;

const CheckmarkSmall = styled.img`
  height: 16px;
`;

const Checkmark = styled.img`
  margin-bottom: 6px;
`;

const ReadMoreLink = styled.a`
  font-size: 16px;
  margin: 24px 0 0px;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  color: ${({ theme }) => theme.pageComponents.shared.infoSideBar.price.textColor} !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  :hover {
    color: ${({ theme }) => theme.buttons.textOnly.textColor} !important;
  }
`;

export default connect(null, mapDispatchToProps)(AddedServices);
