import styled from 'styled-components';
import SmoothCollapse from 'react-smooth-collapse';
import { Table } from '../../../../elements';
import { StyledCaret } from '../../styles';

export const Card = styled.div<{ isChecked: boolean }>`
  background-color: ${({ theme }) => theme.formElements.visualSwitchbox.background};
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 56px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: ${({ isChecked, theme }) =>
    isChecked ? theme.formElements.visualSwitchbox.borderOnActive : theme.formElements.visualSwitchbox.border};

  h3 {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: ${({ theme }) => theme.pageComponents.summary.addOns.card.textColor};
  }

  @media screen and (max-width: 767px) {
    width: 100vw;
    right: calc(100vw * 0.1 + 1px);
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
`;

export const MainCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LabelCont = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MoreInfoCaretCont = styled.button<{ isChecked: boolean }>`
  background: none;
  border: none;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: ${({ isChecked }) => (isChecked ? 'pointer' : 'cursor')};
  }
`;

export const MoreInfoCaret = styled(StyledCaret)`
  margin: 0;
  width: 20px;
`;

export const SwitchCont = styled.div`
  align-self: center;
  margin-right: 16px;
`;

export const StyledSmoothCollapse = styled(SmoothCollapse)`
  margin: 0 8px 8px;
`;

export const StyledTableHeader = styled(Table)`
  th {
    font-size: 14px;
    font-weight: 600;
    background: ${({ theme }) => theme.formElements.visualSwitchbox.background};
  }
`;

export const StyledTableBody = styled(Table)`
  background-color: ${({ theme }) => theme.pageComponents.globals.background};
  border-radius: 4px;

  td {
    padding: 16px 0;
  }
`;

export const InfoBody = styled.div`
  background-color: ${({ theme }) => theme.pageComponents.globals.background};
  border-radius: 4px;
  overflow: auto;
  padding: 0 16px;

  @media screen and (min-width: 769px) {
    max-height: 302px;
  }
`;

export const LimitHeader = styled.th`
  text-align: right;
`;

export const LimitCell = styled.td`
  text-align: right;
`;
