import React from 'react';
import styled from 'styled-components';
import { LabelLarge, PrimaryButton } from '../elements';
import { connect } from 'react-redux';
import { clearAllData } from '../actions/application';
import { quoteHelpCopy } from '../helpers/quoteHelpCopy';
import { isLocal } from '../helpers/environment';
import { ERRORS } from '../constants';
import { useThemeContext } from '../elements/theme/CustomThemeProvider';

interface QuoteHelpProps {
  error?: string;
  clearAllData: () => void;
}

const QuoteHelp = ({ error, clearAllData }: QuoteHelpProps) => {
  if (isLocal()) {
    console.log('error:', error);
  }
  const { theme } = useThemeContext();
  return (
    <HelpCont className="CONT">
      <Title>{quoteHelpCopy(error)}</Title>
      <CenteredLabel>
        {error === ERRORS.CAT_1.INVALID_INDUSTRY_ID
          ? 'If you need help classifying your business call the number below.'
          : 'Please call us at the number below to finish the application.'}
      </CenteredLabel>
      <PhoneImage src={theme.icons.phone} alt="phone icon" />
      <PhoneNumber center>(855) 566–1011</PhoneNumber>
      <StyledButton onClick={() => clearAllData()}>Reset application</StyledButton>
      <CenteredLabel>You may need to refresh the page after hitting the reset button.</CenteredLabel>
    </HelpCont>
  );
};

const HelpCont = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.pageComponents.quoteHelp.title.textColor};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  margin-top: 100px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
`;

const CenteredLabel = styled.p`
  color: ${({ theme }) => theme.pageComponents.quoteHelp.centeredLabel.textColor};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
  font-size: ${({ theme }) => theme.pageComponents.quoteHelp.centeredLabel.fontSize};
  letter-spacing: 0.27px;
  line-height: 20px;
  text-align: center;
`;

const PhoneNumber = styled(LabelLarge)`
  color: ${({ theme }) => theme.pageComponents.quoteHelp.phoneNumber.textColor};
  font-size: 24px;
`;
const PhoneImage = styled.img`
  margin: 20px auto;
  display: block;
  max-width: 100px;
`;

const StyledButton = styled(PrimaryButton)`
  margin: 10px auto;
`;

const mapStateToProps = (state: ReduxState) => ({
  error: state.fatalError,
});

const mapDispatchToProps = { clearAllData };

export default connect(mapStateToProps, mapDispatchToProps)(QuoteHelp);
