import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import StartDate from '../StartDate';
import { setPaymentInterval } from '../../../actions/quote';
import { showNextPage, didViewSummaryPage } from '../../../actions/application';
import handleFormSubmit from '../../../actions/handleFormSubmit';
import { getCurrentPage, getPaymentInterval } from '../../../selectors/routing';
import {
  selectLoadingState,
  selectReasonabilityErrors,
  selectPurchasedPolicies,
  selectPurchasedPolicy,
  selectCombinedPartnerInfo,
  selectIsPremierPlatformPartner,
  selectLocationType,
} from '../../../selectors/index';
import {
  selectQuote,
  selectPremium,
  selectMonthlyPremium,
  selectHasFees,
  selectFeesSavings,
  selectMonth1Fees,
  selectMonth1Owed,
  selectTotalYearlyOwed,
  selectPolicyFees,
  selectInstallmentFees,
  selectHasFirstMonthWaived,
  selectMonthlyOwed,
  selectLossControlFees,
  selectMonthlyFees,
  selectCyberFee,
} from '../../../selectors/quote';
import {
  selectApplication,
  selectPolicyTypes,
  selectUserHasSelectedHasSprinklersOption,
  selectLossControl,
} from '../../../selectors/application';
import { isPageComplete } from '../../../selectors/pageComplete';
import { PAGES } from '../../../constants';
import EndDate from '../../Summary/EndDate';
import { PrimaryButton } from '../../../elements';
import isApplicationBindable from '../../../helpers/isApplicationBindable';
import {
  Sidebar,
  QuoteCont,
  PremiumHeader,
  PriceCont,
  ToggleButtonCont,
  ToggleButton,
  BuyButtonCont,
  DiscountApplied,
  discountButtonCSS,
  PartnerName,
  SavingsNotice,
  FeesContainer,
  FeeAppliedContainer,
  FeeAppliedText,
  PriceRow,
  BreakdownRow,
  BreakdownLabel,
  BreakdownValue,
  TotalDueText,
  TotalDueButton,
} from './styles';
import RenderPremium, { feeNotice } from './RenderPremium';
import PriceDrawer from './PriceDrawer';
import discount_applied_badge from '../../../icons/discount_applied_badge.svg';
import right_arrow_button from '../../../icons/right_arrow_button.svg';
import right_arrow_button_hover from '../../../icons/right_arrow_button_hover.svg';
import ToolTipIcon from '.././ToolTipIcon';
import toolTipContent from '../../../helpers/toolTipContent';
import { formatCurrency } from '../../../helpers/formatNumber';
import { PolicyType } from '../../../types/enums';

interface InfoSidebarProps {
  loading: boolean;
  isSummaryPage: boolean;
  isPaymentPage: boolean;
  page: string;
  paymentInterval: string;
  setPaymentInterval: () => void;
  premium: number;
  monthlyPremium: number;
  hasSprinklers?: boolean;
  isPageComplete: boolean;
  isBindable: boolean;
  premierPlatform: boolean;
  shouldShowQuote: boolean;
  hasReasonabilityErrors: boolean;
  handleFormSubmit: () => void;
  policies: PolicyData[];
  policy: PolicyData;
  partnerInfo: PartnerInfo;
  showFees: boolean;
  feesSavings: number;
  month1Fees: number;
  month1Owed: number;
  totalYearlyOwed: number;
  policyFees: number;
  installmentFees: number;
  hasFirstMonthWaived: boolean;
  monthlyOwed: number;
  lossControl: string | undefined;
  lossControlFees: number;
  monthlyFees: number;
  hasBOPorGL: boolean;
  cyberFee?: Fee;
}

interface PremiumBreakdown {
  label: string;
  value?: any;
  rowStyle?: any;
  labelStyle?: any;
  valueStyle?: any;
  isHidden?: boolean;
}

const InfoSidebar = ({
  loading,
  isSummaryPage,
  page,
  paymentInterval,
  setPaymentInterval,
  premium,
  monthlyPremium,
  isPageComplete,
  isBindable,
  premierPlatform,
  shouldShowQuote,
  hasReasonabilityErrors,
  handleFormSubmit,
  policies,
  policy,
  partnerInfo,
  showFees,
  feesSavings,
  month1Fees,
  month1Owed,
  totalYearlyOwed,
  policyFees,
  installmentFees,
  hasFirstMonthWaived,
  monthlyOwed,
  lossControl,
  lossControlFees,
  monthlyFees,
  hasBOPorGL,
  cyberFee,
}: InfoSidebarProps) => {
  const handleSubmit = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault();
    handleFormSubmit();
  };

  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  const [showPriceDrawer, setShowPriceDrawer] = useState(false);
  const [showPriceDrawerClosing, setShowPriceDrawerClosing] = useState(false);

  const handlePriceDrawerClose = () => {
    setShowPriceDrawer(false);
    setShowPriceDrawerClosing(true);
    setTimeout(() => {
      setShowPriceDrawerClosing(false);
    }, 400);
  };

  const quoteInterval = paymentInterval === 'None' ? 'year' : 'month';
  const hideToggleButtons = isTablet && page === PAGES.PAYMENT;
  const isPaymentPage = page === PAGES.PAYMENT;
  const showLoadingSpinner = shouldShowQuote && loading;
  const RATE_PAGES = [
    PAGES.GENERAL_LIABILITY,
    PAGES.PROFESSIONAL_LIABILITY,
    PAGES.LOCATIONS,
    PAGES.ADDITIONAL_INSUREDS,
    PAGES.CONTACT_INFORMATION,
    PAGES.ADD_ONS,
    PAGES.SUMMARY,
  ];
  const hasPurchasedPolicy = page === PAGES.PAYMENT && Boolean(policies?.length > 0);

  const renderedFeesSavings = formatCurrency(feesSavings);
  const yearlySavingsCopy = `You are saving ${renderedFeesSavings} in fees by paying in full`;
  const monthlySavingsCopy = `Save ${renderedFeesSavings} in fees by paying in full`;
  const savingsNotice = paymentInterval === 'None' ? yearlySavingsCopy : monthlySavingsCopy;
  const totalDueAmount = paymentInterval === 'None' ? formatCurrency(totalYearlyOwed) : formatCurrency(month1Owed);

  const premiumPaid = policies?.reduce((acc, curr) => acc + curr?.premiumPaid, 0);
  const month1FeesPaid = policy?.month1Fees;

  const breakdownStyles = {
    headerRow: {
      'text-transform': 'uppercase',
      'font-size': '12px',
      'font-weight': '600',
      'letter-spacing': '1px',
      'align-items': 'baseline',
      'margin-bottom': '8px',
      color: '#0D0D46',
    },
    totalRow: { 'font-size': '16px', 'font-weight': '600', 'line-height': '21px', color: '#0D0D46' },
    breakdownValues: {
      'font-family': 'Questrial',
      'font-size': '15px',
      'font-weight': '400',
      'line-height': '24px',
      color: '#4A4A4D',
    },
  };

  const paymentPageBreakdown: PremiumBreakdown[] = [
    {
      label: 'Selected Billing Plan',
      value: paymentInterval === 'None' ? 'YEARLY' : 'MONTHLY',
      rowStyle: breakdownStyles.headerRow,
      valueStyle: { background: '#F2F5FA', 'border-radius': '4px', padding: '4px 6px' },
    },
    {
      label: 'Total Due',
      value: paymentInterval === 'None' ? formatCurrency(totalYearlyOwed) : formatCurrency(month1Owed),
      rowStyle: breakdownStyles.totalRow,
    },
    {
      label: 'Premium',
      value: paymentInterval === 'None' ? formatCurrency(premium) : formatCurrency(monthlyPremium),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Policy Fee',
      value: formatCurrency(policyFees),
      isHidden: !Boolean(policyFees),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Installment Fee',
      value: formatCurrency(installmentFees),
      isHidden: hasFirstMonthWaived,
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Manage My Risk Program',
      value: paymentInterval === 'None' ? formatCurrency(lossControlFees * 12) : formatCurrency(lossControlFees),
      isHidden: !Boolean(lossControl === 'OptIn'),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Cyber Claim and Risk Management Fee',
      value: formatCurrency(cyberFee?.amount),
      isHidden: !Boolean(cyberFee?.amount),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Future Monthly Payments',
      value: formatCurrency(monthlyOwed),
      rowStyle: { 'letter-spacing': '1px', 'line-height': '32px' },
      labelStyle: { 'text-transform': 'uppercase', 'font-size': '12px', 'font-weight': '600', color: '#0D0D46' },
      valueStyle: { 'font-family': 'Questrial', 'font-size': '15px', 'font-weight': '400', color: '#4A4A4D' },
      isHidden: paymentInterval === 'None',
    },
  ];

  const paymentConfirmedPageBreakdown: PremiumBreakdown[] = [
    {
      label: 'Payment Summary',
      rowStyle: breakdownStyles.headerRow,
    },
    {
      label: 'Total Amount Paid Today',
      value: formatCurrency(premiumPaid + month1FeesPaid),
      rowStyle: breakdownStyles.totalRow,
    },
    {
      label: 'Premium Paid',
      value: formatCurrency(premiumPaid),
      rowStyle: breakdownStyles.breakdownValues,
    },
    {
      label: 'Fees Paid',
      value: formatCurrency(month1FeesPaid),
      rowStyle: breakdownStyles.breakdownValues,
      isHidden: !Boolean(month1FeesPaid),
    },
  ];

  const renderPaymentPageBreakdown = hasPurchasedPolicy ? paymentConfirmedPageBreakdown : paymentPageBreakdown;

  return (
    <Sidebar>
      {isPaymentPage && (
        <QuoteCont data-cy="payment-breakdown-container" isPaymentPage={isPaymentPage}>
          <PriceCont data-cy="payment-breakdown-box" isPaymentPage={isPaymentPage}>
            {renderPaymentPageBreakdown
              .filter((e) => !e?.isHidden)
              .map((breakdown) => (
                <BreakdownRow key={breakdown.label} style={breakdown?.rowStyle}>
                  <BreakdownLabel style={breakdown?.labelStyle}>{breakdown.label}</BreakdownLabel>
                  {breakdown?.value && (
                    <BreakdownValue style={breakdown?.valueStyle} data-testid={`${breakdown.label}-value`}>
                      {breakdown.value}
                    </BreakdownValue>
                  )}
                </BreakdownRow>
              ))}
          </PriceCont>
        </QuoteCont>
      )}
      {RATE_PAGES.includes(page) && (
        <QuoteCont data-cy="rc1-container">
          {isBindable && <PremiumHeader>Your premium</PremiumHeader>}
          <PriceCont data-cy="quote-box" isSummaryPage={isSummaryPage}>
            <PriceRow>
              {premierPlatform && (
                <PartnerName>
                  with <span>{partnerInfo.name}</span>
                </PartnerName>
              )}
              {isBindable && !hasPurchasedPolicy && !hideToggleButtons && (
                <ToggleButtonCont>
                  <ToggleButton
                    disabled={loading || paymentInterval === 'Monthly'}
                    selected={paymentInterval === 'Monthly'}
                    onClick={() => setPaymentInterval()}
                  >
                    Monthly
                  </ToggleButton>
                  <ToggleButton
                    disabled={loading || paymentInterval === 'None'}
                    selected={paymentInterval === 'None'}
                    onClick={() => setPaymentInterval()}
                  >
                    Yearly
                  </ToggleButton>
                </ToggleButtonCont>
              )}
              <RenderPremium
                showLoadingSpinner={showLoadingSpinner}
                isSummaryPage={isSummaryPage}
                isBindable={isBindable}
                paymentInterval={paymentInterval}
                page={page}
                shouldShowQuote={shouldShowQuote}
                monthlyPremium={monthlyPremium}
                premium={premium}
                showFees={showFees}
                savingsNotice={savingsNotice}
                totalYearlyOwed={totalYearlyOwed}
                month1Owed={month1Owed}
                setShowPriceDrawer={setShowPriceDrawer}
              />
            </PriceRow>
            {isTablet && isSummaryPage && (
              <TotalDueText>
                Total due today: {totalDueAmount}
                <TotalDueButton onClick={() => setShowPriceDrawer(true)}>
                  <img src={right_arrow_button} alt="show total due details" />
                  <img src={right_arrow_button_hover} alt="show total due details" />
                </TotalDueButton>
              </TotalDueText>
            )}
            {isTablet && isSummaryPage && showFees && (
              <SavingsNotice interval={quoteInterval}>{savingsNotice}</SavingsNotice>
            )}
            {premierPlatform && (
              <ToolTipIcon
                data-cy="discount-applied"
                content={toolTipContent['DISCOUNT_APPLIED']}
                toolTipName="Discount_Applied"
                override={() => <DiscountApplied src={discount_applied_badge} alt="5% discount applied" />}
                css={discountButtonCSS}
              />
            )}
          </PriceCont>
          {showFees && isSummaryPage && (
            <FeesContainer>
              {lossControl === 'OptIn' && Boolean(lossControlFees) && (
                <FeeAppliedContainer>
                  <FeeAppliedText data-testid="loss-control-notice">
                    Manage My Risk added for an additional{' '}
                    {paymentInterval === 'None'
                      ? formatCurrency(lossControlFees * 12)
                      : `${formatCurrency(lossControlFees)}/mo`}
                  </FeeAppliedText>
                </FeeAppliedContainer>
              )}
              <FeeAppliedContainer>
                {
                  <ToolTipIcon
                    content={
                      typeof toolTipContent['FEES_APPLIED'] === 'function' &&
                      toolTipContent['FEES_APPLIED'](month1Fees, paymentInterval, feesSavings)
                    }
                    toolTipName="Fees_Applied"
                  />
                }
                <FeeAppliedText>{feeNotice}</FeeAppliedText>
              </FeeAppliedContainer>
            </FeesContainer>
          )}
          {isSummaryPage && (
            <div>
              <StartDate fullWidth disabled={loading} />
              <EndDate disabled={loading} />
            </div>
          )}
          {isBindable && !hasPurchasedPolicy && (
            <BuyButtonCont>
              <PrimaryButton
                onClick={handleSubmit}
                data-cy="go-to-payment"
                disabled={loading || hasReasonabilityErrors || !isPageComplete}
                full
              >
                Pay
              </PrimaryButton>
            </BuyButtonCont>
          )}
        </QuoteCont>
      )}
      {(showPriceDrawer || showPriceDrawerClosing) && (
        <PriceDrawer
          showPriceDrawer={showPriceDrawer}
          onClose={handlePriceDrawerClose}
          isMonthly={paymentInterval !== 'None'}
          monthlyPremium={monthlyPremium}
          premium={premium}
          policyFees={policyFees}
          installmentFees={installmentFees}
          hasFirstMonthWaived={hasFirstMonthWaived}
          hasLossControl={Boolean(lossControl === 'OptIn')}
          lossControlFees={lossControlFees}
          month1Owed={month1Owed}
          totalYearlyOwed={totalYearlyOwed}
          monthlyFees={monthlyFees}
          monthlyOwed={monthlyOwed}
          hasBOPorGL={hasBOPorGL}
          cyberFee={cyberFee}
        />
      )}
    </Sidebar>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const page = getCurrentPage(state);
  const isSummaryPage = page === PAGES.SUMMARY;
  const isPaymentPage = page === PAGES.PAYMENT;
  const reasonabilityErrors = selectReasonabilityErrors(state) || [];
  const userHasSelectedHasSprinklersOption = selectUserHasSelectedHasSprinklersOption(state);
  const policyTypes = selectPolicyTypes(state);
  const hasReasonabilityErrors = reasonabilityErrors.length > 0;
  const hasSelectedBOP = policyTypes.includes(PolicyType.BOP);
  const isOnLocationsPage = selectLocationType(state) === PAGES.LOCATIONS;
  const quote = selectQuote(state);
  const shouldShowQuote =
    !hasReasonabilityErrors &&
    quote?.quotes?.length !== 0 &&
    (isOnLocationsPage && hasSelectedBOP ? userHasSelectedHasSprinklersOption : true);

  return {
    premium: selectPremium(state),
    monthlyPremium: selectMonthlyPremium(state),
    loading: selectLoadingState(state),
    paymentInterval: getPaymentInterval(state),
    page,
    isSummaryPage,
    isPaymentPage,
    reasonabilityErrors: selectReasonabilityErrors(state),
    policies: selectPurchasedPolicies(state),
    policy: selectPurchasedPolicy(state),
    isBindable: isApplicationBindable(selectApplication(state)) && (isSummaryPage || isPaymentPage),
    partnerInfo: selectCombinedPartnerInfo(state),
    premierPlatform: selectIsPremierPlatformPartner(state),
    shouldShowQuote,
    isPageComplete: isPageComplete(state),
    hasReasonabilityErrors,
    showFees: selectHasFees(state),
    feesSavings: selectFeesSavings(state),
    month1Fees: selectMonth1Fees(state),
    month1Owed: selectMonth1Owed(state),
    totalYearlyOwed: selectTotalYearlyOwed(state),
    policyFees: selectPolicyFees(state),
    installmentFees: selectInstallmentFees(state),
    hasFirstMonthWaived: selectHasFirstMonthWaived(state),
    monthlyOwed: selectMonthlyOwed(state),
    lossControl: selectLossControl(state),
    lossControlFees: selectLossControlFees(state),
    monthlyFees: selectMonthlyFees(state),
    hasBOPorGL: policyTypes?.includes(PolicyType.BOP) || policyTypes?.includes(PolicyType.GL),
    cyberFee: selectCyberFee(state),
  };
};

// TODO: Had to change this back to non object shorthand for mapDispatchToProps
// this was because jest.spyOn is not working with object shorthand
const mapDispatchToProps = (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>) => ({
  didViewSummaryPage: (didView: boolean) => dispatch(didViewSummaryPage(didView)),
  showNextPage: () => dispatch(showNextPage()),
  setPaymentInterval: () => dispatch(setPaymentInterval()),
  handleFormSubmit: () => dispatch(handleFormSubmit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoSidebar);
