import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { fetchAvailableEndorsements } from '../api';
import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { fatalError } from './application';
import { setAvailableEndorsements, setIsLoading } from './availableEndorsements';

const handleFetchEndorsements = (): ThunkAction<void, ReduxState, unknown, AnyAction> => {
  return (dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>, getState: () => ReduxState) => {
    dispatch(setIsLoading(true));
    const { application } = getState();
    return fetchAvailableEndorsements(application)
      .then((res) => {
        if (!res.data?.availableEndorsements) {
          dispatch(setAvailableEndorsements(null));
        } else if (res.data.availableEndorsements) {
          dispatch(setAvailableEndorsements(res.data.availableEndorsements));
        }
        dispatch(setIsLoading(false));
      })
      .catch((err: any) => {
        dispatch(fatalError(err));
        dispatch(setIsLoading(false));
        reportToSentry(err, getRequestContext({ application }));
      });
  };
};

export default handleFetchEndorsements;
