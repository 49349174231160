import React from 'react';
import GhostButton from '../Components/Shared/GhostButton';
import { Container, Paragraph } from '../elements';
import AddOnsComponent from '../Components/AddOns';
import handleFormSubmit from '../actions/handleFormSubmit';
import { connect } from 'react-redux';
import Spacer from '../Components/Shared/Spacer';

const pageDescription =
  'Based on your choices, we think you should consider ' +
  'the following additional coverages. ' +
  'Select the ones you want to add to your policy.';

const AddOns = ({ handleFormSubmit }: { handleFormSubmit: () => void }) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleFormSubmit();
  };

  return (
    <Container data-cy="Add-Ons-Page">
      <Paragraph>{pageDescription}</Paragraph>
      <Spacer height={28} />
      <form onSubmit={handleSubmit}>
        <AddOnsComponent />
        <GhostButton />
      </form>
    </Container>
  );
};

export default connect(null, { handleFormSubmit })(AddOns);
