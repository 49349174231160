import React, { useState } from 'react';
import { LocationFormCont, LocationTypeCont, LocationTypeButton } from './styles/locationEditor';
import {
  LabelLarge,
  FormSection,
  SectionTitle,
  NumberInput,
  InputErrorMessage,
  LabelWithToolTipCont,
  InputWithToolTip,
} from '../../elements';
import SmoothCollapse from 'react-smooth-collapse';
import MultiRow from '../Shared/MultiRow';
import ToolTipIcon from '../Shared/ToolTipIcon';
import SectionDivider from '../Shared/SectionDivider';
import YesNoQuestion from '../Shared/YesNoQuestion';
import {
  VALUE_OPTIONS,
  POLICY_OPTIONS,
  PROPERTY_LABELS,
  TOOL_TIP_CONTENT_KEY,
  INPUT_ERROR_MSG,
  QUOTE_COVERAGE_DEFAULTS,
} from '../../constants';
import { debounce } from 'lodash';
import { NumberFormatValues } from 'react-number-format';
import { TypesWithBuildingLimit, LocationTypeOptions } from '../../types/enums';
import { DesktopAndTablet } from '../../helpers/responsive';
import toolTipContent from '../../helpers/toolTipContent';
import { formatCurrency } from '../../helpers/formatNumber';
import { useFeatureFlags } from '../../toggle_tools/featureFlagTools';

interface LocationEditorProps {
  updateLocation: (data: Partial<Location>) => void;
  updateBPPDeductible: (setBppDeductible: number) => void;
  bppDeductible?: number;
  bppLimit?: number;
  buildingLimit?: number;
  locationState?: string;
  expanded?: Location;
  locationType?: string;
  hasSprinklers?: boolean;
  userHasSelectedHasSprinklersOption: boolean;
  isLoading: boolean;
  setUserSelectedHasSprinklersOption: () => void;
}

interface BppLimitError {
  type: string;
  value?: number;
}

interface BuildingLimitError {
  type: string;
  value?: number;
}

const locationTypeOptions = Object.keys(LocationTypeOptions) as (keyof typeof LocationTypeOptions)[];

const LocationEditor = ({
  updateLocation,
  updateBPPDeductible,
  bppDeductible,
  bppLimit,
  buildingLimit,
  locationState,
  expanded,
  locationType,
  hasSprinklers,
  userHasSelectedHasSprinklersOption,
  isLoading,
  setUserSelectedHasSprinklersOption,
}: LocationEditorProps) => {
  const [bppLimitError, setBppLimitError] = useState<BppLimitError | null>(null);
  const [buildingLimitError, setBuildingLimitError] = useState<BuildingLimitError | null>(null);

  const addSpaces = (string: string) => string.replace(/([A-Z])/g, ' $1').trim();

  const updateLocationType = (type: string) => {
    if (type in TypesWithBuildingLimit) {
      updateLocation({ locationType: type, buildingLimit: QUOTE_COVERAGE_DEFAULTS.BUILDING_LIMIT });
    } else {
      updateLocation({ locationType: type, buildingLimit: 0 });
    }
  };

  const updateBppLimit = debounce((value: NumberFormatValues) => {
    if (value.floatValue == null) return;
    if (value.floatValue > 500_000) {
      setBppLimitError({ type: 'BPP_OVER_LIMIT', value: value.floatValue });
    } else if (value.floatValue < 5_000) {
      setBppLimitError({ type: 'BPP_UNDER_LIMIT', value: value.floatValue });
    } else {
      setBppLimitError(null);
      updateLocation({ bppLimit: value.floatValue });
    }
  }, 500);

  const updateBuildingLimit = debounce((value: NumberFormatValues) => {
    if (value.floatValue == null) return;
    if (value.floatValue > 1_000_000) {
      setBuildingLimitError({ type: 'BUILDING_OVER_LIMIT', value: value.floatValue });
    } else if (value.floatValue < 25_000) {
      setBuildingLimitError({ type: 'BUILDING_UNDER_LIMIT', value: value.floatValue });
    } else {
      setBuildingLimitError(null);
      updateLocation({ buildingLimit: value.floatValue });
    }
  }, 500);

  const updateFireSuppression = (value: boolean) => {
    updateLocation({ hasSprinklers: value });
    setUserSelectedHasSprinklersOption();
  };

  const isExpanded = Boolean(expanded && Object.keys(expanded).length > 0);

  const { increaseFlPropertyMin } = useFeatureFlags();

  const TEMPORARY_PROPERTY_CLAIMS_DEDUCTIBLE_FL = [250, 500, 1000, 2500];

  const propertyClaimsOptionsFlorida = increaseFlPropertyMin
    ? VALUE_OPTIONS[POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE_FL]
    : TEMPORARY_PROPERTY_CLAIMS_DEDUCTIBLE_FL;

  const propertyClaimsOptions =
    locationState === 'FL' ? propertyClaimsOptionsFlorida : VALUE_OPTIONS[POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE];

  return (
    <LocationFormCont expanded={isExpanded} data-cy="location-editor">
      <SmoothCollapse heightTransition=".5s ease" expanded={isExpanded} allowOverflowWhenOpen={true}>
        <LabelLarge>In what type of building do you operate your business?</LabelLarge>
        <LocationTypeCont>
          {locationTypeOptions.map((type) => (
            <LocationTypeButton
              key={type}
              active={type === locationType}
              onClick={() => updateLocationType(type)}
              data-cy={`location-type-${type}`}
              aria-label={`${type} location type`}
              type="button"
            >
              {addSpaces(type)}
            </LocationTypeButton>
          ))}
        </LocationTypeCont>
        <YesNoQuestion
          question="Does it have an automatic fire suppression system?"
          value={userHasSelectedHasSprinklersOption ? hasSprinklers : undefined}
          selectYes={() => updateFireSuppression(true)}
          selectNo={() => updateFireSuppression(false)}
          cyLabel="sprinkler-system"
        />
        <SectionDivider />
        <SectionTitle>Property Coverages</SectionTitle>
        <FormSection available={true} smaller={true}>
          <LabelWithToolTipCont id="propertyDeductible">
            <LabelLarge display="inline" data-cy="property-deductible">
              For property claims, I want to pay a deductible of
            </LabelLarge>
            <ToolTipIcon
              content={toolTipContent[TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]]}
              labelElementId="propertyDeductible"
              toolTipName="Property_Claims_Deductible"
            />
          </LabelWithToolTipCont>
          {propertyClaimsOptions.length > 3 ? (
            <MultiRow
              money={true}
              options={propertyClaimsOptions}
              value={bppDeductible}
              updateValue={(value) => updateBPPDeductible(value)}
              label={PROPERTY_LABELS[POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]}
            />
          ) : (
            <LocationTypeCont>
              {propertyClaimsOptions.map((propertyClaimsOption) => (
                <LocationTypeButton
                  key={propertyClaimsOption}
                  active={propertyClaimsOption === bppDeductible}
                  onClick={() => updateBPPDeductible(propertyClaimsOption)}
                  data-cy={`property-claims-${propertyClaimsOption}`}
                  aria-label={`${PROPERTY_LABELS[POLICY_OPTIONS.PROPERTY_CLAIMS_DEDUCTIBLE]}: ${propertyClaimsOption}`}
                  type="button"
                >
                  {formatCurrency(propertyClaimsOption)}
                </LocationTypeButton>
              ))}
            </LocationTypeCont>
          )}
        </FormSection>
        <FormSection available={true} smaller={true}>
          <LabelLarge display="inline">For my business property and equipment, I'd like to be covered up to</LabelLarge>
          <DesktopAndTablet>
            <ToolTipIcon
              content={toolTipContent[TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]]}
              toolTipName="Business_Personal_Property_Limit"
            />
          </DesktopAndTablet>
          <InputWithToolTip
            toolTipContentKey={TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]}
            toolTipName="Business_Personal_Property_Limit"
            labelElementId="bppLimitInput"
          >
            <NumberInput
              value={bppLimitError?.value ? bppLimitError.value : bppLimit}
              onValueChange={updateBppLimit}
              customWidth="quarter"
              marginBottom="none"
              thousandSeparator={true}
              prefix="$"
              aria-label={PROPERTY_LABELS[POLICY_OPTIONS.BUSINESS_PERSONAL_PROPERTY_LIMIT]}
              data-cy="bppLimit"
              disabled={isLoading}
              hasError={Boolean(bppLimitError)}
              placeholder="e.g., $150,000"
              id="bppLimitInput"
            />
          </InputWithToolTip>
          {bppLimitError && <InputErrorMessage>{INPUT_ERROR_MSG[bppLimitError.type]}</InputErrorMessage>}
        </FormSection>
        <SmoothCollapse
          expanded={Boolean(locationType && locationType in TypesWithBuildingLimit)}
          allowOverflowWhenOpen={true}
        >
          <FormSection available={true} smaller={true}>
            <LabelLarge display="inline">For my building, I would like to be covered up to</LabelLarge>
            <DesktopAndTablet>
              <ToolTipIcon
                content={toolTipContent[TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.BUILDING_LIMIT]]}
                toolTipName="Building_Limit"
              />
            </DesktopAndTablet>
            <InputWithToolTip
              toolTipContentKey={TOOL_TIP_CONTENT_KEY[POLICY_OPTIONS.BUILDING_LIMIT]}
              toolTipName="Building_Limit"
              labelElementId="buildingLimitInput"
            >
              <NumberInput
                value={buildingLimitError?.value ? buildingLimitError.value : buildingLimit}
                onValueChange={updateBuildingLimit}
                customWidth="quarter"
                marginBottom="none"
                thousandSeparator={true}
                prefix="$"
                aria-label={PROPERTY_LABELS[POLICY_OPTIONS.BUILDING_LIMIT]}
                data-cy="buildingLimit"
                disabled={isLoading}
                hasError={Boolean(buildingLimitError)}
                placeholder="e.g., $150,000"
                id="buildingLimitInput"
              />
            </InputWithToolTip>
            {buildingLimitError && <InputErrorMessage>{INPUT_ERROR_MSG[buildingLimitError.type]}</InputErrorMessage>}
          </FormSection>
        </SmoothCollapse>
      </SmoothCollapse>
    </LocationFormCont>
  );
};

export default LocationEditor;
