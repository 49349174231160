export enum PolicyType {
  GL = 'GL',
  PL = 'PL',
  BOP = 'BOP',
}

export enum LocationTypes {
  BuildingOwned = 'BuildingOwned',
  CommercialCondoOwned = 'CommercialCondoOwned',
}

export enum ApplicationStatus {
  Incomplete = 'Incomplete',
  ReadyToQuote = 'ReadyToQuote',
}

export enum HiredCoverageTypes {
  None = 'None',
  HiredAutoLiability = 'HiredAutoLiability',
}

export enum NonOwnedCoverageTypes {
  None = 'None',
  NonOwnedAutoLiabilityWithoutDeliveryService = 'NonOwnedAutoLiabilityWithoutDeliveryService',
  NonOwnedAutoLiabilityWithDeliveryService = 'NonOwnedAutoLiabilityWithDeliveryService',
}

export enum EndorsementEnum {
  'liquorLiabilityEndorsement' = 'liquorLiabilityEndorsement',
  'restaurantCoverage' = 'restaurantCoverage',
  'equipmentBreakdownEndorsement' = 'equipmentBreakdownEndorsement',
  'hiredAutoAndNonOwnedAuto' = 'hiredAutoAndNonOwnedAuto',
}

export enum TypesWithBuildingLimit {
  BuildingOwned,
  CommercialCondoOwned,
  BuildingLeasedToOthers,
}

export enum TypesWithoutBuildingLimit {
  CommercialCondoLeased,
  Home,
  BuildingLeased,
  Kiosk,
  Other,
}

export enum LocationTypeOptions {
  Home = 'Home',
  BuildingLeased = 'BuildingLeased',
  BuildingOwned = 'BuildingOwned',
}

export enum BlanketAIItem {
  primaryAndNonNonContributory = 'primaryAndNonNonContributory',
  waiverOfTransfer = 'waiverOfTransfer',
  constructionContractRequirement = 'constructionContractRequirement',
  managersOrLessorsPremises = 'managersOrLessorsPremises',
  lessorOfLeasedEquipment = 'lessorOfLeasedEquipment',
}
