import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LabelLarge, Input, Container, InputCont, InputBox, InputErrorMessage } from '../elements';
import formatPhone from '../helpers/formatPhone';
import sanitizePhoneNumber from '../helpers/sanitizePhoneNumber';
import AddressInput from '../Components/Shared/AddressInput';
import handleFormSubmit from '../actions/handleFormSubmit';
import { setContactFirstName, setContactLastName, setContactEmail, setContactPhone } from '../actions/application';
import {
  selectContactFirstName,
  selectContactLastName,
  selectContactEmail,
  selectContactPhone,
  selectMailingAddressStreet,
  selectContactEmailIsValid,
} from '../selectors/application';
import GhostButton from '../Components/Shared/GhostButton';
import { validatePhoneNumber } from '../helpers/inputValidation/validatePhoneNumber';
import { useValidateEmail } from '../helpers/inputValidation/validateEmail';
import styled from 'styled-components';
import { INPUT_ERROR_MSG } from '../constants';
import ValidationWarning from '../Components/Shared/ValidationWarning';

interface PropTypes {
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: string;
  mailingAddressStreet?: string;
  setContactFirstName: (value: string) => void;
  setContactLastName: (value: string) => void;
  setContactEmail: (value: string) => void;
  setContactPhone: (value: string) => void;
  handleFormSubmit: () => void;
  emailIsValid?: boolean;
}

const Contact: FunctionComponent<PropTypes> = ({
  contactFirstName,
  contactLastName,
  contactEmail,
  contactPhone,
  setContactFirstName,
  setContactLastName,
  setContactEmail,
  setContactPhone,
  handleFormSubmit,
  emailIsValid,
}) => {
  const { validateEmail, emailValidationError, emailValidationWarning, hasEmailWarning } = useValidateEmail();

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  useEffect(() => {
    document.title = 'Coterie - Contact';
    window.scrollTo(0, 0);
  }, []);

  const updateContactFirstName = (e: { target: HTMLInputElement }) => setContactFirstName(e.target.value);
  const updateContactLastName = (e: { target: HTMLInputElement }) => setContactLastName(e.target.value);
  const updateContactEmail = (e: { target: HTMLInputElement }) => setContactEmail(e.target.value);
  const updateContactPhone = (e: { target: HTMLInputElement }) =>
    setContactPhone(sanitizePhoneNumber(e.target.value).slice(0, 10));

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleFormSubmit();
  };

  const onPhoneNumberBlur = () => setIsPhoneNumberValid(validatePhoneNumber(contactPhone));
  const onEmailBlur = (e: { target: HTMLInputElement }) => validateEmail(e.target.value);

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <InputCont>
          <InputBox>
            <LabelLarge>First name</LabelLarge>
            <Input
              value={contactFirstName}
              onChange={updateContactFirstName}
              data-cy="contact-first-name"
              aria-label="First Name"
              required
              aria-required
              type="text"
            />
          </InputBox>
          <InputBox>
            <LabelLarge>Last name</LabelLarge>
            <Input
              value={contactLastName}
              onChange={updateContactLastName}
              data-cy="contact-last-name"
              aria-label="Last Name"
              required
              aria-required
              type="text"
            />
          </InputBox>
        </InputCont>
        <LabelLarge>Email</LabelLarge>
        <StyledInput
          value={contactEmail}
          onChange={updateContactEmail}
          onBlur={onEmailBlur}
          hasError={emailIsValid === false}
          data-cy="contact-email"
          aria-label="Email"
          type="email"
          required
          aria-required
        />
        {!emailIsValid && emailValidationError && (
          <StyledInputErrorMessage>{emailValidationError}</StyledInputErrorMessage>
        )}
        {hasEmailWarning && (
          <ValidationWarning message={emailValidationWarning} collapsedMessage="View invalid email warning" />
        )}
        <LabelLarge>Phone</LabelLarge>
        <StyledInput
          value={formatPhone(contactPhone)}
          onChange={updateContactPhone}
          onBlur={onPhoneNumberBlur}
          hasError={!isPhoneNumberValid}
          data-cy="contact-phone"
          aria-label="Phone Number"
          customWidth="halfWithMargin"
          marginBottom="larger"
          type="tel"
          required
          aria-required
        />
        {
          <StyledInputErrorMessage>
            {!isPhoneNumberValid && INPUT_ERROR_MSG.INVALID_PHONE_NUMBER}
          </StyledInputErrorMessage>
        }
        <AddressInput />
        <GhostButton />
      </form>
    </Container>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  contactFirstName: selectContactFirstName(state),
  contactLastName: selectContactLastName(state),
  contactEmail: selectContactEmail(state),
  contactPhone: selectContactPhone(state),
  mailingAddressStreet: selectMailingAddressStreet(state),
  emailIsValid: selectContactEmailIsValid(state),
});

const mapDispatchToProps = {
  setContactFirstName,
  setContactLastName,
  setContactEmail,
  setContactPhone,
  handleFormSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);

const StyledInput = styled(Input)`
  margin-bottom: 8px;
`;

const StyledInputErrorMessage = styled(InputErrorMessage)`
  height: 16px;
  margin: 0 0 24px;
`;
