import reducer from './reducers';
import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routePaths } from './routing/routes';
import { connectRoutes } from 'redux-first-router';
import middleware from './middleware';
import queryString from 'query-string';

import * as Sentry from '@sentry/react';
import { generateSentryReduxOptions, stateDenyList } from './helpers/sentry';
import {
  SET_CONTACT_FIRST_NAME,
  SET_CONTACT_LAST_NAME,
  SET_CONTACT_EMAIL,
  SET_CONTACT_PHONE,
  SET_EXISTING_MAILING_ADDRESS,
  ADD_EXISTING_MAILING_TO_LOCATIONS,
  ADD_LOCATION,
  UPDATE_LOCATION,
  SET_LOCATIONS,
  ADD_ADDITIONAL_INSURED,
} from '../src/actions/application';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['location', 'applicationMetadata', 'partnerInfo', 'fatalError', 'dropin'],
};

const actionsDenyList = [
  ADD_ADDITIONAL_INSURED,
  ADD_LOCATION,
  UPDATE_LOCATION,
  SET_LOCATIONS,
  SET_CONTACT_FIRST_NAME,
  SET_CONTACT_LAST_NAME,
  SET_CONTACT_EMAIL,
  SET_CONTACT_PHONE,
  SET_EXISTING_MAILING_ADDRESS,
  ADD_EXISTING_MAILING_TO_LOCATIONS,
  'persist/REHYDRATE',
];

const sentryReduxEnhancer = Sentry.createReduxEnhancer(generateSentryReduxOptions(actionsDenyList, stateDenyList));

const { middleware: routerMiddleware, enhancer: routerEnhancer, initialDispatch } = connectRoutes(routePaths, {
  querySerializer: queryString,
  initialDispatch: false,
});

export default function configureStore() {
  const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
    : compose;
  const persistedReducer = persistReducer(persistConfig, reducer);

  const store = createStore(
    persistedReducer,
    undefined,
    composedEnhancers(routerEnhancer, applyMiddleware(routerMiddleware, ...middleware), sentryReduxEnhancer)
  );

  initialDispatch();

  // expose store when run in Cypress
  if (window.Cypress) {
    window.store = store;
  }

  const persistor = persistStore(store);

  return { store, persistor };
}
