import { quoteReceived, fatalError } from './application';
import { rateCallOne } from '../api';
import { applicationError, clearApplicationError, setQuoteIsLoading } from './quote';
import { getRequestContext, reportToSentry } from '../helpers/sentry';
import { Dispatch } from 'redux';
import { addSource } from '../helpers/addSource';
import { getIsDropin } from '../selectors/routing';
import { selectIsAgentMDA } from '../selectors/agent';

const handleRateCallOne = (application: Application) => {
  return (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(setQuoteIsLoading(true));

    const sourceData = addSource({ isDropin: getIsDropin(getState()), isMDA: selectIsAgentMDA(getState()) });

    const requestData = {
      ...application,
      ...sourceData,
    };

    return rateCallOne(requestData)
      .then((res) => {
        dispatch(setQuoteIsLoading(false));
        dispatch(clearApplicationError());
        if (!res.data.isSuccess && res.data.errors.length > 0) {
          dispatch(applicationError(res.data.errors));
        }
        dispatch(quoteReceived(res.data.quote));
      })
      .catch((error) => {
        dispatch(setQuoteIsLoading(false));
        dispatch(fatalError(error));
        reportToSentry(error, getRequestContext({ requestData }));
      });
  };
};

export default handleRateCallOne;
