import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { sentrySetup } from './helpers/sentry';
import './index.css';
import App from './App';
import configureStore from './configureStore';
import ErrorBoundary from './Components/Global/ErrorBoundary';
import CustomThemeProvider from './elements/theme/CustomThemeProvider';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { isProd, isSandbox } from './helpers/environment';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { FeatureFlagProvider } from './toggle_tools/featureFlagTools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient();

const { store, persistor } = configureStore();

sentrySetup();

(async () => {
  const ldClientId =
    isProd() || isSandbox() ? '620430a5b3158915332e07ba' : process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;
  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: ldClientId,
  });
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <CustomThemeProvider>
            <ErrorBoundary>
              <LaunchDarklyProvider>
                <FeatureFlagProvider>
                  <App />
                  <ReactQueryDevtools />
                </FeatureFlagProvider>
              </LaunchDarklyProvider>
            </ErrorBoundary>
          </CustomThemeProvider>
        </QueryClientProvider>
      </PersistGate>
      <ToastContainer />
    </Provider>,
    document.getElementById('root')
  );
})();
