import {
  QUOTE_RECEIVED,
  CLEAR_APPLICATION,
  IS_LOADING,
  CLEAR_ALL_DATA,
  UPDATE_APPLICATION_LOCALLY,
  BINDABLE_QUOTE_RECEIVED,
  CLEAR_QUOTE,
} from '../actions/application';
import {
  POLICY_PURCHASED,
  QUOTE_PURCHASE_PENDING,
  APPLICATION_ERROR,
  POLICY_PURCHASE_FAILED,
  SET_PAYMENT_INTERVAL,
  SET_QUOTE_IS_LOADING,
  CLEAR_APPLICATION_ERROR,
  SET_EXCLUSION_DATA,
} from '../actions/quote';

const initialState = {
  quotes: [],
};

export function quote(state = initialState, action) {
  switch (action.type) {
    case QUOTE_RECEIVED:
      return { ...action.data };
    case BINDABLE_QUOTE_RECEIVED:
      return { ...action.data };
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
    case CLEAR_QUOTE:
      return initialState;
    case SET_EXCLUSION_DATA:
      return Object.assign({}, state, { exclusions: action.data });
    default:
      return state;
  }
}

export function purchasedPolicy(state = {}, action) {
  switch (action.type) {
    case POLICY_PURCHASED:
      return action.data;
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
    case UPDATE_APPLICATION_LOCALLY:
      return [];
    default:
      return state;
  }
}

export function quoteBindPending(state = false, action) {
  switch (action.type) {
    case QUOTE_PURCHASE_PENDING:
      return !state;
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
    case POLICY_PURCHASED:
    case UPDATE_APPLICATION_LOCALLY:
    case POLICY_PURCHASE_FAILED:
      return false;
    default:
      return state;
  }
}

export function reasonabilityErrors(state = [], action) {
  switch (action.type) {
    case APPLICATION_ERROR:
      return [...state, ...action.data];
    case CLEAR_APPLICATION_ERROR:
    case QUOTE_PURCHASE_PENDING:
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
    case POLICY_PURCHASED:
    case BINDABLE_QUOTE_RECEIVED:
    case IS_LOADING:
      return [];
    default:
      return state;
  }
}

export function policyPurchaseErrors(state = [], action) {
  switch (action.type) {
    case POLICY_PURCHASE_FAILED:
      return [...action.data];
    case QUOTE_PURCHASE_PENDING:
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
    case POLICY_PURCHASED:
    case BINDABLE_QUOTE_RECEIVED:
    case IS_LOADING:
      return [];
    default:
      return state;
  }
}

export function paymentInterval(state = 'Monthly', action) {
  switch (action.type) {
    case SET_PAYMENT_INTERVAL:
      return state === 'Monthly' ? 'None' : 'Monthly';
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
    case CLEAR_QUOTE:
      return 'Monthly';
    default:
      return state;
  }
}

export function quoteIsLoading(state = false, action) {
  switch (action.type) {
    case SET_QUOTE_IS_LOADING:
      return action.data;
    case CLEAR_APPLICATION:
    case CLEAR_ALL_DATA:
    case CLEAR_QUOTE:
      return false;
    default:
      return state;
  }
}
