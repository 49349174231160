import { Middleware } from 'redux';
import { AVAILABLE_ENDORSEMENTS_ACTIONS } from '../actions/availableEndorsements';
import { selectEndorsements } from '../selectors/application';
import { getAvailableEndorsements } from '../selectors/availableEndorsements';
import { setCyberCoverage } from '../actions/application';

const setEndorsementDefaults: Middleware<{}, ReduxState> = (store) => (next) => (action) => {
  const result = next(action);
  const { type } = action;

  if (type === AVAILABLE_ENDORSEMENTS_ACTIONS.SET_AVAILABLE_ENDORSEMENTS) {
    const state = store.getState();
    const endorsements = selectEndorsements(state);
    const availableEndorsements = getAvailableEndorsements(state);
    const currentCyberCoverage = endorsements?.cyberCoverage;

    // this condition should be true only if the user hasn't already deselected cyber coverage
    if (availableEndorsements.cyberCoverage && currentCyberCoverage === undefined) {
      store.dispatch(setCyberCoverage(JSON.parse(availableEndorsements.cyberCoverage)));
    }
  }

  return result;
};

export default setEndorsementDefaults;
